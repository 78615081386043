import React from 'react';
import './App.css'; 

// import Navbar from './components/Navbar.js';
import CallToAction from './components/CallToAction.js';
// import ServiceCard from './components/ServiceCard.js';
import ContactForm from './components/ContactForm.js';

function App() {
  return (
    <div className="App">

      {/* <Navbar /> */}

      <div className="content">
        
        <div className="call-to-action-container">
          <CallToAction />
        </div>

        {/* <div className="services-container">
          <ServiceCard title="1. Service" description="TBC" />
          <ServiceCard title="2. Service" description="TBC" />
          <ServiceCard title="3. Service" description="TBC" />
        </div>
         */}
        <div className="contact-form-container">
          <div className="contact-form-content">
            <div className="contact-form-fixed">
              <ContactForm />
              {/* <div className="copyright">&copy; {new Date().getFullYear()} ImmuLab</div> */}
            </div>
            {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div className="copyright">&copy; {new Date().getFullYear()} Kaia Health</div>
              </div> */}
          </div>  
        </div>    
      </div>      
    
    </div>
  );
}

export default App;